import React, {useState} from 'react';

const ProgressLine = ({onReset}) => {


    return (
        <button className='reset-button' onClick={onReset}/>
    )
}

export default ProgressLine;
