import React, {useState} from "react";

function getQueryParam(name) {
    const url = new URL(window.location.href);
    return url.searchParams.get(name);
}
function Form({pokerNetwork, gameId, source, allPokerNetworks, disabled}) {
    console.log('Form source', source)
    const initialGameIdValue = getQueryParam("game_id") || gameId;
    const initialPokerNetworkValue = getQueryParam("poker_network") || pokerNetwork;
    const initialSourceValue = getQueryParam("source") || source;

    const [gameIdValue, setGameIdValue] = useState(initialGameIdValue);
    const [pokerNetworkValue, setPokerNetworkValue] = useState(initialPokerNetworkValue);
    const [sourceValue, setSourceValue] = useState(initialSourceValue);

    const handleSubmit = (event) => {
        event.preventDefault();
        // Create a URL object from the current URL
        const currentURL = new URL(window.location.href);

        // Clear all existing query parameters
        currentURL.search = `?poker_network=${pokerNetworkValue}&game_id=${gameIdValue}&source=${sourceValue}`;

        // Redirect to the updated URL
        window.location.href = currentURL.toString();
    };

    return disabled ? <></> : (
        <div>
            <form onSubmit={handleSubmit}>
                <select value={pokerNetworkValue} onChange={(e) => setPokerNetworkValue(e.target.value)}>

                    {allPokerNetworks.map(option => (
                        <option key={option} value={option}>{option}</option>
                    ))}

                </select>
                <input
                    type="text"
                    value={gameIdValue}
                    onChange={(e) => setGameIdValue(e.target.value)}
                />
                <select value={sourceValue} onChange={(e) => setSourceValue(e.target.value)}>

                    {["mga", "mining"].map(option => (
                        <option key={option} value={option}>{option}</option>
                    ))}

                </select>
                <button type="submit">Submit</button>
            </form>
        </div>
    );
}

export default Form;