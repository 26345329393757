import mergeObjects from "./mergeObjects";


function mergeObjectsCumulatively(objects) {
    return objects.reduce((results, currentDict) => {
        let lastResult = results[results.length - 1] || {};
        let merged = mergeObjects(lastResult, currentDict);
        results.push(merged);
        return results;
    }, [])
}
export default mergeObjectsCumulatively;