let emptyReplayHistoryUpdates = [
  {
    "TableID": "",
    "TableAnte": 0,
    "TableSmallBlind": 0,
    "TableBigBlind": 0,
    "TableStraddle": 0,
    "StartedDateTime": "",
    "PokerNetwork": "",
    "GameID": "",
    "GameType": "NL",
    "Street": "PREFLOP",
    "PlayerIDs": [
    ],
    "Participants": {
    },
    "Pots": [
      0
    ],
    "Board": ""
  }
];
export default emptyReplayHistoryUpdates;
