import React from 'react'

function ArrowButton({value, isDisable, updateEventIndex}) {
    function onHandle() {
        updateEventIndex(value === '+' ? 1 : -1)
    }

    return (
        <button className={`arrow-button ${value === '+' ? '' : 'arrow-button__left'}`}
                disabled={isDisable}
                onClick={onHandle}
        />

    )
}


export default ArrowButton