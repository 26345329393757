import React, {useState} from 'react'


function Banner({text}) {
    return  text === ""? <></> : (
        <div className='banner__container'>
            <div className="banner__text">
                {text}
            </div>
        </div>
    )
}


export default Banner