import React from 'react'
import Form from "./Form";
import Eye from "./Eye";


function GameInfo({
                      smallBlind,
                      bigBlind,
                      ante,
                      tableTime,
                      tableID,
                      gameID,
                      pokerNetwork,
                      source,
                      allPokerNetworks,
                      showForm,
                      visitsCount,
                      directAccess
                  }) {
    return (
        <div className="game-info__container">
             <Eye visitsCount={visitsCount}/>

            <span className="game-id__title" key="game_id">{pokerNetwork}: {gameID}</span>
            <Form gameId={gameID} pokerNetwork={pokerNetwork} source={source} allPokerNetworks={allPokerNetworks} disabled={!showForm}/>
            <div className="game-info__inner-container">
                <div className='table-bb' key="table_bb">
                    <div className="table-bb--icon"/>
                    <span>{`${smallBlind / 100}/${bigBlind / 100}(${ante / 100})`}</span>
                </div>
                <div className='table-time' key="table_time">
                    <div className="table-time--icon"/>
                    <span>{tableTime}</span>
                </div>
                <div className='table-id' key="table_id">
                    <div className="table-id--icon"/>
                    <span>{tableID}</span>
                </div>
            </div>
        </div>
    )
}


export default GameInfo