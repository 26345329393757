import React from 'react'

function Pot({bank, className}) {
    return (
        <span className={'pot ' + className}>
            {bank ? (bank / 100).toLocaleString() : '0'}
        </span>

    )
}


export default Pot