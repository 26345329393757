import React, {useEffect, useState, useMemo, useCallback} from "react";
import {useLocation} from 'react-router-dom';
import Table from "./Table/Table";
import GameInfo from "./GameInfo";
import Banner from "./Banner";
import mergeObjectsCumulatively from "./mergeObjectsCumulatively";
import sampleReplayHistoryUpdates from "./sampleReplayHistoryUpdates";
import emptyReplayHistoryUpdates from "./emptyReplayHistoryUpdates";

function Replayer({directAccess}) {
    const location = useLocation()

    const [state, setState] = useState({
        replayHistory: null,
        eventIndex: 0,
        bannerText: "",
        visitsCount: 0,
    })
    const [allPokerNetworksState, setAllPokerNetworksState] = useState({allPokerNetworks: []})

    // - Get replay history

    const queryParameters = new URLSearchParams(window.location.search)

    // - Debug query parameters

    const pokerNetwork = queryParameters.get("poker_network")
    const gameId = queryParameters.get("game_id")
    const source = queryParameters.get("source") || "mga"

    // - Get backend host

    // todo later: it is better to use "proxy" in package.json, but it does not work for some reason in my setup, need to investigate
    const backendHost = process.env.REACT_APP_BACKEND_HOST || "http://localhost:8000"

    // - Get endpoint

    let endpoint = directAccess ? "/api/replay_history_updates" : "/api/replay_history_updates_by_game_url"

    // - Get params string

    let paramsString = location.pathname.replace("direct", "").slice(1) + location.search

    // - Fetch replay history

    useEffect(() => {
        if ((directAccess) && !(pokerNetwork === null || gameId === null)) {
            // Update poker networks
            fetch(`${backendHost}/api/poker_networks`)
                .then(response => {
                    if (!response.ok) {
                        throw new Error("HTTP error " + response.status);
                    }
                    return response.json();
                })
                .then(allPokerNetworks => setAllPokerNetworksState({
                    allPokerNetworks: allPokerNetworks,
                }));
        }

        if ((directAccess) && (pokerNetwork === null || gameId === null)) {
            // debug
            setState({
                replayHistory: mergeObjectsCumulatively(sampleReplayHistoryUpdates),
                eventIndex: 0,
                allPokerNetworks: ["PMTR", "WE"],
                bannerText: "Sample hand"
            })
        } else {
            // Update replay history
            fetch(`${backendHost}${endpoint}${paramsString}`)
                .then(response => {
                    if (!response.ok) {
                        throw new Error("HTTP error " + response.status);
                    }
                    return response.json();
                })
                .then(replayHistoryUpdates => {
                    if (Object.keys(replayHistoryUpdates?.replay_history_updates).length > 0) {
                        setState({
                            replayHistory: mergeObjectsCumulatively(replayHistoryUpdates.replay_history_updates),
                            eventIndex: 0,
                            bannerText: "",
                            visitsCount: replayHistoryUpdates.visits_count,
                        });
                    } else {
                        setState({
                            replayHistory: mergeObjectsCumulatively(emptyReplayHistoryUpdates),
                            eventIndex: 0,
                            bannerText: `Game not found: ${pokerNetwork}, ${gameId}`
                        });
                    }
                });
        }
    }, [gameId, pokerNetwork, paramsString, backendHost, directAccess, endpoint]);

    // - Update event index

    const updateEventIndex = useCallback((delta) => {
        setState({
            ...state, eventIndex: Math.max(0, Math.min(state.eventIndex + delta, state?.replayHistory?.length - 1))
        })
    }, [state])

    // - Reset event index

    const onReset = useCallback(() => {
        setState({...state, eventIndex: 0})

    }, [state])

    // - Render after loading

    const tableState = useMemo(() => {
        if (state?.replayHistory) {
            return state.replayHistory[0]
        }
        return {}
    }, [state.replayHistory]);

    // - updateEventIndex when pressing arrow buttons

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (document.activeElement.tagName === "INPUT") {
                return
            }
            if (event.key === 'ArrowLeft') {
                updateEventIndex(-1)
            } else if (event.key === 'ArrowRight') {
                updateEventIndex(1)
            }
        }
        window.addEventListener('keydown', handleKeyDown)
        return () => {
            window.removeEventListener('keydown', handleKeyDown)
        }
    }, [updateEventIndex])

    // - onReset when pressing space

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === ' ') {
                onReset()
            }
        }
        window.addEventListener('keydown', handleKeyDown)
        return () => {
            window.removeEventListener('keydown', handleKeyDown)
        }
    }, [onReset])

    return (!state?.replayHistory ? <></> : <div>

        {/* - Banner */}

        <Banner text={state.bannerText}/>

        {/* - Table */}

        <div className='table__container'>
            <Table state={state} updateEventIndex={updateEventIndex} onReset={onReset}/>
        </div>

        {/* - GameInfo */}

        <GameInfo
            smallBlind={tableState.TableSmallBlind}
            bigBlind={tableState.TableBigBlind}
            ante={tableState.TableAnte ? tableState.TableAnte : ''}
            tableTime={tableState.StartedDateTime}
            tableID={tableState.TableID}
            gameID={tableState.GameID}
            pokerNetwork={tableState.PokerNetwork}
            source={source}
            allPokerNetworks={allPokerNetworksState.allPokerNetworks}
            showForm={directAccess}
            visitsCount={state.visitsCount}
            directAccess={directAccess}
        />
    </div>);
}

export default Replayer;
