import React from 'react'

function Bet({bet, className}) {
    return (
        <span className={className}

        >
            {(bet / 100).toLocaleString()}
        </span>
    )
}


export default Bet