import React, {useEffect, useMemo, useState} from "react";
import Player from "./Player/Player";
import chroma from 'chroma-js';


function Players({players, gameType}) {

    const playersPositionRef = React.useRef(null)
    const [distributedPlayers, setDistributedPlayers] = useState(null)

    useEffect(() => {
        setDistributedPlayers(distributeOnCircle(players?.length, 9))
    }, [players])


    // get profit array
    const profitArray = useMemo(() => {
            const result = []
            players?.forEach((player) => {
                result.push(player?.Profit)
            })
            return result
        }
        , [players])


    const distributeOnCircle = (countItem, itemSize) => {
        const resultPlayers = []
        const resultBank = []

        for (let index = 0; index < 10; index++) {
            let position = index + 1
            let isEmpty = countItem < position
            const player = players[position - 1];

            const playerProfitColor = calculateColor(profitArray, 0, 255, 0, 255)[position - 1]

            resultPlayers.push(
                <Player
                    key={player ? player?.PlayerID : `Player${position}Empty`}
                    player={player}
                    gameType={gameType}
                    position={position}
                    itemSize={itemSize}
                    isEmpty={isEmpty}
                    profitColor={playerProfitColor}

                />)
        }
        return [resultPlayers, resultBank]
    }

    const calculateColor = (profits) => {
        // Находим максимальный выигрыш и проигрыш
        const maxLoss = Math.min(...profits);
        const maxGain = Math.max(...profits);

        // Определение цветовых шкал для выигрышей и проигрышей
        const greenScale = chroma.scale(['bbd7b1', '7ab621']);
        const redScale = chroma.scale(['f0bbb9', 'ff362e']);

        // Вычисление цвета для каждого профита
        return profits.map(profit => {
            if (profit > 0) {
                const relativeProfit = profit / maxGain;
                return greenScale(relativeProfit).hex();
            } else {
                const relativeProfit = profit / maxLoss; // profit и maxLoss оба отрицательны, поэтому это положительное число
                return redScale(relativeProfit).hex();
            }
        });
    }


    return (
        <div id="players_position" ref={playersPositionRef} className='players_position'>
            {distributedPlayers}
        </div>
    )
}

export default Players;
