import React from 'react'

function Card({cardCode, className}) {
    return (
        <div className={`card ${className}`}>
            {cardCode ? <img style={{
                width: 'inherit',
                height: 'inherit',
            }}
                             src={`/images/cards/${cardCode}.svg`}
                             alt="card"
            /> : null}
        </div>


    )
}


export default Card