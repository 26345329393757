import React, {useState} from 'react';

const ProgressLine = ({currentIndex, historyLength}) => {


    // Вычисление процента
    const percentage = (currentIndex / (historyLength - 1)) * 100;

    return (
        <div className='progress-line__container'>
            <div style={{width: `${percentage}%`}} className='progress-line'></div>
        </div>
    )
}

export default ProgressLine;
