import React from "react";
import Card from "./Card";

function Board({board}) {
    return (
        <div className='board__container'>
            <Card key={1} cardCode={board.slice(0, 2) ? board.slice(0, 2) : null}/>
            <Card key={2} cardCode={board.slice(2, 4) ? board.slice(2, 4) : null}/>
            <Card key={3} cardCode={board.slice(4, 6) ? board.slice(4, 6) : null}/>
            <Card key={4} cardCode={board.slice(6, 8) ? board.slice(6, 8) : null}/>
            <Card key={5} cardCode={board.slice(8, 10) ? board.slice(8, 10) : null}/>
        </div>
    )
}

export default Board;
