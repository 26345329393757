import React from 'react'

function Eye({visitsCount}) {


    return (
        <div className="eye">
            <img style={{
                width: 'inherit',
                height: 'inherit',
            }}
                 src={`/images/eye.svg`}
                 alt="card"
            />
            <text className="eye__text">{visitsCount}</text>
        </div>
    )
}


export default Eye