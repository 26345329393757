import React from 'react'

const streetActionText = {
    SMALL_BLIND: 'SB',
    BIG_BLIND: 'BB',
    FOLD: 'fold',
    CHECK: 'check',
    CALL: 'call',
    RAISE: 'raise',
    ALL_IN: 'allin',
    ALLIN: 'allin',
    STRADDLE: 'straddle',
    ANTE: 'ante',
    BET: 'bet',
}

const streetAction = {
    SMALL_BLIND: 'SMALL_BLIND',
    BIG_BLIND: 'BIG_BLIND',
    FOLD: 'FOLD',
    CHECK: 'CHECK',
    CALL: 'CALL',
    RAISE: 'RAISE',
    ALL_IN: 'ALL_IN',
    ALLIN: 'ALLIN',
    STRADDLE: 'STRADDLE',
    ANTE: 'ANTE',
    BET: 'BET',

}

function PlayerAction({player}) {

    const getclassNameByStreetAction = (action) => {
        if (!action) {
            return ''
        }
        switch (action) {
            case streetAction.SMALL_BLIND:
                return 'player-action--small-blind'
            case streetAction.BIG_BLIND:
                return 'player-action--big-blind'
            case streetAction.FOLD:
                return 'player-action--fold'
            case streetAction.CHECK:
                return 'player-action--check'
            case streetAction.CALL:
                return 'player-action--call'
            case streetAction.RAISE:
                return 'player-action--raise'
            case streetAction.ALL_IN:
                return 'player-action--all-in'
            case streetAction.ALLIN:
                return 'player-action--all-in'
            case streetAction.STRADDLE:
                return 'player-action--straddle'
            case streetAction.BET:
                return 'player-action--bet'
            default:
                return ''
        }
    }
    // console.log(player.CurrentStreetAction)
    return (
        <div className='player-action-container'>
            <span className={`player-action ${getclassNameByStreetAction(player.CurrentStreetAction)}`}
            >
                {streetActionText[player.CurrentStreetAction]}
            </span>
        </div>
    )
}


export default PlayerAction