function Dealer() {
    return (
        <span className="dealer"
        > <span className="dealer-text">
            D
        </span>
        </span>
    )
}


export default Dealer