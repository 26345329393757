function mergeObjects(obj1, obj2) {
    let merged = {...obj1};

    for (let key in obj2) {
        if (obj2.hasOwnProperty(key)) {
            if (obj1[key] && typeof obj1[key] === 'object' && obj2[key] && typeof obj2[key] === 'object') {
                merged[key] = mergeObjects(obj1[key], obj2[key]);
            } else {
                merged[key] = obj2[key];
            }
        }
    }

    return merged;
}
export default mergeObjects;

// let listOfDictionaries = [{"a": 1}, {"a": {"b": 2}}, {"a": {"c": 3}}];
// let merged = listOfDictionaries.reduce(mergeObjects, {}) // {a: {b: 2, c: 3}}
