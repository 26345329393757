import React from "react";
import './App.css';
import Replayer from "./Replayer/Replayer";
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';

function App() {
    return (
        <div className="App">
            <Router>
                <Routes>
                    <Route path="/" element={<Replayer directAccess={false}/>}/>
                    <Route path="/direct" element={<Replayer directAccess={true}/>}/>
                </Routes>
            </Router>
        </div>
    )
}

export default App;
